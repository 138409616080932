<template lang="pug">
    v-app#main
        v-card.wnd
            v-toolbar(color='indigo' dark)
                v-toolbar-title Zaloguj
            v-card-text(@keyup.enter="login")
                v-text-field(v-model="form.login" label="Login")
                v-text-field(v-model="form.password" label="Hasło" type="password")
            v-alert(:value="errorMessage.length > 0" type="error") {{errorMessage}}
            v-card-actions.text-sm-right
                v-spacer
                v-btn(@click="login") Zaloguj
</template>

<script>
export default {
    name: 'Main',
    data: () => ({
        form: {
            login: '',
            password: '',
        },
        errorMessage: '',
    }),
    methods: {
        async login() {
            const res = await this.$axios.post('/login/', this.form);
            if (res.data.status === 'ok') {
                this.errorMessage = '';
                window.location = window.next;
            } else {
                this.errorMessage = 'Hasło i login nie pasują';
            }
        },
    },
};
</script>

<style scoped lang="scss">
#main {
    position: absolute;
    height: 100%;
    width: 100%;

    .wnd {
        position: relative;
        margin: 100px auto;
        width: 400px;
    }
}
</style>
