import Vue from 'vue';
import View from './Main.vue';
import vuetify from '../../plugins/vuetify';

require('../base');

new Vue({
    render: h => h(View),
    vuetify,
}).$mount('#app');
